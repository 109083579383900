html,
body {
    padding: 0;
    margin: 0;
    min-height: 100vh;

    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
main{
    min-height: 83vh;
}
a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.footer {
    width: 100%;
    background-color: #00384b;
    height: 100px;
    border-top: 1px solid #eaeaea;
    display: flex;
    position: static;
    bottom: 0;
    flex-direction: column;
    align-items: center;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    color: #f39200;
}